<template>
  <page :title="i18n.title">
    <div class="shop-warp">
      <div class="shop-main">
        <img
          class="shop-banner"
          :src="
            shopDetails.shopBanner || require('@/assets/imgs/banner-home.jpg')
          "
          alt=""
        />
        <div class="shop-gourp">
          <span class="shop-gourp-label"></span>
          <p class="shop-gourp-val shopName">{{ shopDetails.shopName }}</p>
        </div>
        <div class="shop-gourp">
          <span class="shop-gourp-label">{{ i18n.shop_power_banks }}</span>
          <p class="shop-gourp-val">{{ shopDetails.freeNum }}</p>
        </div>
        <div class="shop-gourp">
          <span class="shop-gourp-label">{{ i18n.shop_empty_slots }}</span>
          <p class="shop-gourp-val">
            {{ parseInt(shopDetails.batteryNum - shopDetails.freeNum) }}
          </p>
        </div>
        <div class="shop-gourp">
          <span class="shop-gourp-label">{{ i18n.shop_address }}</span>
          <p class="shop-gourp-val">{{ shopDetails.shopAddress1 }}</p>
        </div>
        <div class="shop-gourp">
          <span class="shop-gourp-label">{{ i18n.shop_business_hours }}</span>
          <p class="shop-gourp-val">{{ shopDetails.shopTime }}</p>
        </div>
        <div
          class="shop-status"
          :class="onlineStatus(shopDetails.infoStatus).class"
        >
          {{ onlineStatus(shopDetails.infoStatus).text }}
        </div>
      </div>
      <div class="shop-footer">
        <!-- <div class="btn nav-station" @click="goNearbyShop">
          {{ i18n.shop_directions_station }}
        </div>
        <div class="btn" @click="goRouter('/?isScan=true')">
          {{ i18n.shop_scan_qrcode }}
        </div> -->
        <div class="btn" @click="goNearbyShop">
          {{ i18n.shop_directions_station }}
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
import { SHOP_LIST } from "../../apis/cabinet"
export default {
  components: {},
  computed: {
    ...mapState(["system", "shopInfo"]),
    i18n() {
      return this.$t("shop")
    }
  },
  data() {
    return {
      error: "",
      loading: false,
      shopDetails: {}
    }
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    // 附近站点
    goNearbyShop() {
      const jumpPath = `https://www.google.com/maps/dir/`
      window.open(
        `${jumpPath}?api=1&destination=${this.shopDetails.latitude},${this.shopDetails.longitude}&travelmode=bicycling`
      )
    },
    // 获取状态
    onlineStatus(infoStatus) {
      return {
        text: this.$t(`map.${infoStatus}`),
        class: infoStatus === "在线" ? "online" : ""
      }
    },
    /**
     * 店铺详情
     */
    getShopDetails(id) {
      const shop = this.shopInfo
      if (shop.id === parseInt(id)) {
        this.shopDetails = shop
      }
    }
  },

  mounted() {
    const { pathMatch } = this.$route.params
    this.getShopDetails(pathMatch)
  },
  destroyed() {
    // this.$store.commit("shopInfo", null)
  }
}
</script>
<style lang="less" scoped>
.shop-warp {
  min-height: 80vh;
  background-color: #fff;
  padding: 56px;
}
.shop-main {
  position: relative;
  padding-bottom: 42px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 2px 2px #d5d5d5;
  .shop-banner {
    width: 100%;
    min-height: 300px;
    max-height: 400px;
    object-fit: contain;
  }
  .shop-gourp {
    margin: 18px 36px 0;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.5);
    &-val {
      color: #41200f;
      font-size: 26px;
      margin: 6px 0 0;
    }
    .shopName {
      font-size: 36px;
    }
  }

  .shop-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 24px 8px 36px;
    border-radius: 0 18px 0 56px;
    background-color: #d5d5d5;
    &.online {
      background-color: #398e3c;
    }
  }
}
.shop-footer {
  position: relative;
  margin: 78px 0;
  .nav-station {
    margin-bottom: 24px;
    border: 2px solid #41200f;
    border-radius: 9px;
    color: #398e3c;
    background-color: transparent;
  }
}
</style>
