<template>
  <div class="nearby-shop">
    <div class="nearby-head">
      <img
        class="nearby-head-icon maps-icon"
        src="@/assets/imgs/shop/icon_shop_nav2.png"
        alt=""
        @click="goNearbyShop(item)"
      />
      <div class="shop_scan" @click="onClick('shop_scan')">
        <img
          alt=""
          class="shop_scan_pic"
          :src="require('@/assets/imgs/shop/img_logo_scan.png')"
        />
      </div>
      <img
        @click="onClick('shop_close')"
        class="nearby-head-icon"
        src="@/assets/imgs/shop/icon_shop_close.png"
        alt=""
      />
    </div>
    <div class="shop-warp" @click="shopDetails(item)">
      <div class="item-warp">
        <div class="item-gourp">
          <span class="item-shopname">{{ item.shopName }}</span>
        </div>
        <div class="item-gourp">
          <img src="@/assets/imgs/shop/icon_shop_address.png" alt="" />
          <span>{{ item.shopAddress1 }}</span>
        </div>
        <div class="item-gourp">
          <img src="@/assets/imgs/shop/icon_shop_time.png" alt="" />
          <span>{{ item.shopTime }}</span>
        </div>
        <div class="item-gourp">
          <div class="item-gourp">
            <img src="@/assets/imgs/shop/icon_shop_cabinet.png" alt="" />
            <span>{{ item.freeNum }} {{ $t("shop.shop_power_banks") }}</span>
          </div>
          <div class="item-gourp">
            &emsp;
            <img src="@/assets/imgs/shop/icon_charge_available.png" alt="" />
            <span
              >{{ parseInt(item.batteryNum - item.freeNum) }}
              {{ $t("shop.shop_empty_slots") }}</span
            >
          </div>
          <div class="item-gourp"></div>
        </div>
      </div>
      <div class="shop-cabinet">
        <img
          class="shop-cabinet-pic"
          src="@/assets/imgs/shop/img_shop_cabinet.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    i18n() {
      return this.$t("map")
    }
  },
  methods: {
    onClick(type) {
      this.$emit("click", type)
    },
    // 站点详情
    shopDetails(item) {
      this.$store.commit("shopInfo", item)
      this.$router.push(`/shop/details/${item.id}`)
    },
    // 附近站点
    goNearbyShop() {
      const jumpPath = `https://www.google.com/maps/dir/`
      window.open(
        `${jumpPath}?api=1&destination=${this.shopDetails.latitude},${this.shopDetails.longitude}&travelmode=bicycling`
      )
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
.nearby-shop {
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: 36px;
  width: calc(100vw - 72px);
  background-color: #fff;
}
.nearby-head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 28px;
  .shop_scan {
    position: absolute;
    left: 50%;
    bottom: 25%;
    width: 176px;
    height: 176px;
    transform: translateX(-50%);
    background-color: #132e3d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &_pic {
      width: 60%;
      height: 60%;
    }
  }
  &-icon {
    width: 52px;
  }
  .maps-icon {
    border-radius: 50%;
    background-color: #93d500;
  }
}
.shop-warp {
  display: flex;
  .shop-cabinet {
    width: 192px;
    &-pic {
      width: 100%;
    }
  }
}
.item-warp {
  flex: 1;
  .item-gourp {
    display: flex;
    align-items: center;
    margin: 4px 0 14px;
    color: #a9abad;
    font-size: 22px;
    img {
      width: 36px;
      margin-right: 8px;
    }
    .item-shopname {
      color: #41200f;
      font-size: 36px;
    }
    &-near {
      display: flex;
      align-items: center;
      margin-left: 24px;
      padding: 12px 20px;
      border-radius: 12px;
      background-color: #ebebeb;
      img {
        width: 26px;
      }
    }
  }
}
.shop-info {
  position: fixed;
  bottom: 0;
  z-index: 100;
  // height: 350px;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #fff;
  padding-bottom: 30px;

  .shop-info-head {
    // width: 100%;
    display: flex;
    padding: 20px 20px 0 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .shop-title {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;

      h3 {
        padding: 0;
        margin: 0;
      }

      .shop-tag {
        margin-left: 20px;
        font-size: 24px;
        padding: 5px 15px;
        border: 1px solid #cacdd1;
        border-radius: 10px;
      }

      .shop-tag-active {
        color: #8dd35f;
        border: 1px solid #8dd35f;
      }
    }

    img {
      width: 60px;
      height: 60px;
    }
  }

  .shop-info-content {
    display: flex;
    height: calc(100% - 49px);
    padding: 0px 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .shop-logo {
      width: 200px;

      img {
        max-height: 180px;
        max-width: 200px;
      }
    }

    .shop-detail {
      height: 100%;
      margin-left: 14px;

      p {
        margin: 0;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-all;
      }

      .shop-time {
        margin: 15px 0;
        font-size: 22px;
        line-height: 30px;

        .time {
          padding: 4px 0;
          position: relative;
          padding-left: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
        }

        // .time::before {
        //   content: '';
        //   position: absolute;
        //   height: 25px;
        //   width: 2px;
        //   top: 10px;
        //   left: 0;
        //   background: #23a8f2;
        // }
      }

      .shop-op {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;

        .return-num {
          margin-left: 20px;
          padding: 5px 10px;
          border: 1px solid #23aaf2;
          border-radius: 10px;
          color: #23aaf2;
        }

        .free-num {
          padding: 5px 10px;
          border: 1px solid #8dd35f;
          border-radius: 10px;
          color: #8dd35f;
        }
      }
    }
  }
}
</style>
