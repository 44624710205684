<template>
  <div class="item-warp" @click="shopDetails(item)">
    <img
      alt=""
      class="shop-banner"
      :src="item.shopBanner || require('@/assets/imgs/banner-home.jpg')"
    />
    <div class="item-gourp">
      <span class="item-shopname">{{ item.shopName }}</span>
    </div>
    <div class="item-gourp">
      <img src="@/assets/imgs/shop/icon_shop_address.png" alt="" />
      <span>{{ item.shopAddress1 }}</span>
    </div>
    <div class="item-gourp">
      <img src="@/assets/imgs/shop/icon_shop_time.png" alt="" />
      <span>{{ item.shopTime || "-" }}</span>
    </div>
    <div class="item-gourp">
      <img src="@/assets/imgs/shop/icon_shop_cost.png" alt="" />
      <span>
        <!-- {{ item.currencyName }} {{ item.pjifei }} / {{ item.pjifeiDanwei }}
        {{ $t("home.minute") }} -->

        {{ priceStrategy(item) }}
      </span>
    </div>
    <div class="item-gourp">
      <div class="item-gourp-row">
        <img src="@/assets/imgs/shop/icon_shop_cabinet.png" alt="" />
        <span>{{ item.freeNum }} {{ i18n.shop_power_banks }}</span>
      </div>
      <div class="item-gourp-row">
        <img src="@/assets/imgs/shop/icon_charge_available.png" alt="" />
        <span
          >{{ parseInt(item.batteryNum - item.freeNum) }}
          {{ i18n.shop_empty_slots }}</span
        >
      </div>
      <div class="item-gourp-row">
        <div class="item-gourp-near">
          <img src="@/assets/imgs/shop/icon_shop_nav.png" alt="" />{{
            item.distance
          }}
        </div>
      </div>
    </div>
    <div class="shop-status" :class="onlineStatus(item.infoStatus).class">
      {{ onlineStatus(item.infoStatus).text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    i18n() {
      return this.$t("shop")
    }
  },
  methods: {
    // 站点详情
    shopDetails(item) {
      this.$store.commit("shopInfo", item)
      this.$router.push(`/shop/details/${item.id}`)
    },
    // 获取状态
    onlineStatus(infoStatus) {
      return {
        text: this.$t(`map.${infoStatus}`),
        class: infoStatus === "在线" ? "online" : ""
      }
    },

    // 价格策略
    priceStrategy(shop = {}) {
      const strategy = shop.priceStrategy || shop
      let keepT = this.$t("home.minute")
      // 计时 pPriceMinute  单位 priceUnit 0-分钟 1-小时 2-天
      if (strategy.priceUnit === 1) {
        keepT = this.$t("home.hour")
      } else if (strategy.priceUnit === 2) {
        keepT = this.$t("home.days")
      }
      const price = `${shop.currencyName + shop.pjifei}`
      return `${price} / ${strategy.pjifeiDanwei} ${keepT}`
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
.item-warp {
  position: relative;
  padding: 36px;
  border: 2px solid #d5d5d5;
  border-radius: 20px;
  margin-bottom: 32px;
  .shop-banner {
    width: 100%;
    height: 204px;
    object-fit: contain;
  }
  .item-gourp {
    display: flex;
    align-items: center;
    margin: 4px 0;
    color: #a9abad;
    font-size: 22px;
    img {
      width: 36px;
      margin-right: 8px;
    }
    .item-shopname {
      color: #41200f;
      font-size: 36px;
    }
    &-near {
      display: flex;
      align-items: center;
      margin-left: 24px;
      padding: 12px 20px;
      border-radius: 12px;
      background-color: #ebebeb;
      img {
        width: 26px;
      }
    }

    &-row {
      display: flex;
      align-items: center;
    }
  }
  .shop-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 24px 8px 36px;
    border-radius: 0 18px 0 56px;
    background-color: #d5d5d5;
    &.online {
      background-color: #398e3c;
    }
  }
}
</style>
