<template>
  <page :title="i18n.payment_title">
    <div class="payment_warp">
      <div class="payment_warp-label">
        <span>{{ i18n.pay_type }}</span>
      </div>
      <div class="payment-card">
        <template v-for="item in payModeList">
          <div
            :key="item.pId"
            v-if="
              item.phone === 'all' ||
              item.phone === system ||
              item.phone === browser ||
              (item.phone === 'phone' && system !== '')
            "
          >
            <div
              class="confirm-payment-item"
              :class="{
                bordermaincolor:
                  payMode === item.pPaymentType && item.pId === pId
              }"
            >
              <div class="confirm-payment-item-info">
                <img
                  v-if="
                    item.pPaymentType === 'GOOGLE_PAY' ||
                    item.pPaymentType === 'STRIPE_GOOGLE'
                  "
                  :src="require('../../assets/imgs/icon_googlepay.png')"
                />
                <img
                  v-else-if="
                    item.pPaymentType === 'APPLE_PAY' ||
                    item.pPaymentType === 'STRIPE_APPLE'
                  "
                  :src="require('../../assets/imgs/icon_applepay.png')"
                />
                <img
                  v-else-if="item.pPaymentType === 'PAYPAL'"
                  :src="require('../../assets/imgs/icon_paypal.png')"
                />
                <img
                  v-else
                  :src="require('../../assets/imgs/icon_creditcard.png')"
                />
                <div class="content">
                  <div class="title">{{ item.label }}</div>
                </div>
              </div>
              <div
                v-if="item.paymentConfig"
                class="confirm-payment-item-operate"
              >
                <span
                  class="confirm-payment-item-operate-text"
                  @click="deletePayCard(item)"
                  >{{ i18nConfirm.deletecard }}</span
                >
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="confirm-btns">
        <div class="btn" @click="onAddPayment">
          {{ i18n.add_payment_card }}
        </div>
      </div>
    </div>
    <popup-warp v-model="isCreditcard">
      <div>
        <div class="creditcard-input">
          <div id="credit-card-element"></div>
        </div>
        <div class="btn creditcard-btn" @click="onStripeSetup">
          {{ i18n.add_payment }}
        </div>
      </div>
    </popup-warp>
  </page>
</template>

<script>
import { mapState } from "vuex"

import {
  PAYMODE,
  STRIPE_SAVE_CARD,
  STRIPE_SAVE_CARD_CONFIRM
} from "../../apis/payment"
import { USER_CARD, REMOVE_CARD } from "../../apis/user"

import stripeMixin from "../../mixins/stripe"
import { PopupWarp } from "../../components/popup"

export default {
  mixins: [stripeMixin],
  components: {
    PopupWarp
  },
  computed: {
    ...mapState(["system", "user", "qrcode", "agentId"]),
    i18n() {
      return this.$t("payment")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    i18nConfirm() {
      return this.$t("confirm")
    }
  },
  data() {
    return {
      payModeList: [],
      payMode: "STRIPE_CARD",
      pId: "", // 代理或平台支付配置id
      currencySymbol: "$",
      country: "US", // 国家
      currencyname: "usd", // 货币类型
      publicKey: "", // 支付通道的公钥
      paymentConfig: {},
      isCreditcard: false
    }
  },

  methods: {
    // 添加支付方式
    onAddPayment() {
      console.log(`payMode-${this.payMode}`)
      if (this.payMode === "PAYPAL") {
        this.onPaypal()
      } else if (
        this.payMode === "APPLE_PAY" ||
        this.payMode === "STRIPE_APPLE" ||
        this.payMode === "STRIPE_GOOGLE" ||
        this.payMode === "GOOGLE_PAY"
      ) {
        this.stripePayRequest.show()
      } else if (
        this.payMode === "STRIPE_CARD" ||
        this.payMode === "STRIPE_KLARNA"
      ) {
        this.saveCard(this.publicKey)
      } else if (
        this.payMode === "MIDTRANS_CARD" ||
        this.payMode === "MIDTRANS_GOPAY"
      ) {
        this.onMidtrans()
      } else if (this.payMode === "MyFatoorah") {
        this.onMyfatoorahCreditCard(this.publicKey)
      } else {
        this.$loading(true)
        setTimeout(() => {
          this.$loading(false)
          this.$router.replace("/result")
        }, 3000)
      }
    },
    // 删除支付卡
    deletePayCard(v) {
      this.$post(
        `${REMOVE_CARD + v.pId}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.$toast(this.i18nMsg.success)
          this.getUserPayCard()
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        }
      )
    },
    saveCard() {
      console.log("this.payMode", this.payMode)
      if (!this.user) {
        this.$toast(this.i18nMsg.unlogin)
        return
      }
      if (!this.payMode || !this.pId) {
        this.$toast(this.i18nMsg.nopaymode)
        return
      }
      let url = `${STRIPE_SAVE_CARD}?paymentId=${this.pId}`
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.creditOrder = resp.data
          this.isCreditcard = true
          this.onLoadElement()
          console.log("saveCard====", resp)
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveCard()
          })
          console.log(error)
        }
      )
    },

    onStripeSetup(paymentWay) {
      const vm = this
      vm.$loading(true)
      // 失败事件回调
      const failCall = (result) => {
        console.log(result)
        vm.$loading(false)
        if (result.error) {
          vm.$toast(result.error.message)
        }
      }
      const { rentSave } = this.$route.query
      let _path = `${window.location.origin}#/payment_card?`
      let returnUrl = `${_path}?paymentId=${this.pId}&pmid=${this.creditOrder.paymentId}&rentSave=${rentSave}`
      window.stripe
        .confirmSetup({
          elements: vm.creditcard,
          confirmParams: { return_url: returnUrl }
        })
        .then((result) => failCall(result))
    },
    onConfirmSaveCard(pid, id) {
      const { rentSave } = this.$route.query
      this.$loading(true)
      const query = {
        paymentId: pid,
        paymentMethod: id
      }
      this.$post(
        STRIPE_SAVE_CARD_CONFIRM,
        query,
        (resp) => {
          this.$loading(false)
          console.log("saveCardConfirm====", resp)
          if (rentSave) {
            this.$router.replace("/home?cabinetshow=true")
            return
          }
          this.getUserPayCard()
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.onConfirmSaveCard(pid, id)
          })
          console.log(error)
        }
      )
    },

    // 获取用户支付卡信息
    async getUserPayCard() {
      this.$loading(true)
      const qrcode = this.qrcode || this.$route.query.cabinetID
      const { data = [] } = await this.$Request("get", USER_CARD, {
        qrcode,
        orderId: "",
        agentld: this.agentId
      })
      let cardList = []
      if (data && data.length) {
        cardList = data.map((item) => {
          return {
            phone: "all",
            label: item.pCardnumber,
            ...item.paymentConfig,
            ...item
          }
        })
        this.cardList = cardList
      }
      this.$loading(false)
      this.payModeList = cardList
    },
    // 获取支付方式列表
    getPayMode() {
      console.log("hahah", this.cabinet, this.wallet)
      this.$loading(true)
      let agentId = ""
      if (this.payType === "CZ") {
        agentId = this.wallet
          ? this.wallet.pDailiId
          : this.cabinet.userWallet.pDailiId
      }
      this.$get(
        this.payType !== "CZ"
          ? `${PAYMODE}?id=`
          : `${PAYMODE}?agentId=${agentId}`,
        {},
        async (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            let list = resp.data
            list.map((v, i, arr) => {
              if (v.pPaymentType === "STRIPE_CARD") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (
                v.pPaymentType === "APPLE_PAY" ||
                v.pPaymentType === "STRIPE_APPLE"
              ) {
                v.label = "Apple Pay"
                v.phone = "ios"
              } else if (
                v.pPaymentType === "GOOGLE_PAY" ||
                v.pPaymentType === "STRIPE_GOOGLE"
              ) {
                v.label = "Google Pay"
                v.phone = "android"
              } else if (v.pPaymentType === "PAYPAL") {
                v.label = "Paypal"
                v.phone = "all"
              } else if (v.pPaymentType === "STRIPE_KLARNA") {
                v.label = "Klarna"
                v.phone = "all"
              } else if (v.pPaymentType === "MIDTRANS_CARD") {
                v.label = "Credit Card"
                v.phone = "all"
              } else if (v.pPaymentType === "MIDTRANS_GOPAY") {
                v.label = "GOPAY"
              } else if (v.pPaymentType === "MyFatoorah") {
                v.label = "Credit Card"
                v.phone = "all"
              }
              // list.push(v)
            })
            list = list.filter((v) => !!v.label)

            // this.payModeList = list

            if (list.length > 0) {
              this.payMode = list[0].pPaymentType
              this.currencySymbol = list[0].currencySymbol // 货币符号
              this.currencyname = list[0].configDetails.currency // 货币类型
              this.publicKey = list[0].publicKey || ""
              this.paymentConfig = list[0].paymentConfig
              this.pId = list[0].pId
              this.country = list[0].configDetails.accountCountry
              console.log(this.publicKey, this.payMode)
              if (
                list[0].publicKey &&
                list[0].pPaymentType !== "M-PESA" &&
                list[0].pPaymentType !== "MIDTRANS_CARD" &&
                list[0].pPaymentType !== "MIDTRANS_GOPAY"
              ) {
                this.loadStripeScript(list[0].publicKey)
              }
            }
            console.log("list======", list)
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        }
      )
      this.getUserPayCard()
    }
  },
  created() {
    const { paymentId, pmid } = this.$route.query
    if (paymentId && pmid) {
      this.onConfirmSaveCard(paymentId, pmid)
    }
    this.getPayMode()
  }
}
</script>
<style lang="less" scoped>
.payment_warp {
  min-height: 92vh;
  overflow-y: scroll;
  background: #ffffff;
  &-label {
    font-size: 36px;
    color: #000;
    font-weight: bold;
    text-align: center;
    margin-top: 60px;
  }
}
.payment-card {
  margin: 30px;
}
</style>
