<template>
  <page>
    <div class="home-head">
      <img class="home-logo" src="../../assets/imgs/img_logo.png" alt="" />
      <div class="home-head-title">
        <p class="home-head-title-small">Welcome to</p>
        <p class="home-head-title-big">{{ appName }}</p>
      </div>
    </div>
    <img class="home-bg" :src="homebg" alt="" />
    <div class="contact-foot">
      <div class="download-dark">
        <img
          alt=""
          @click="onAppStore"
          src="../../assets/imgs/appstore_dark.png"
        />
        <img
          alt=""
          @click="onGooglePlay"
          src="../../assets/imgs/googleplay_dark.png"
        />
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
export default {
  watch: {},
  computed: {
    ...mapState(["system"]),
    i18n() {
      return this.$t("result")
    },
    appName() {
      return process.env.VUE_APP_NAME
    },
    homebg() {
      return `${process.env.VUE_APP_DOMAIN}/content/introduction1.png`
    }
  },
  data() {
    return {}
  },
  methods: {
    onAppStore() {
      console.log("11111")
      window.open(process.env.VUE_APP_APP_STORE)
      // window.location.href = process.env.VUE_APP_APP_STORE
    },
    onGooglePlay() {
      console.log("22222")
      window.open(process.env.VUE_APP_GOOGLE_PLAY)
      // window.location.href = process.env.VUE_APP_GOOGLE_PLAY
    }
  }
}
</script>

<style lang="less">
.contact-foot {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}
.download-dark {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
  img {
    width: 50%;
    display: block;
  }

  img:last-child {
    margin-top: 20px;
  }
}
</style>
