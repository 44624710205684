<template>
  <page :title="i18n.wallet" :showBack="false">
    <div class="wallet-balance" @click="goRouter('/wallet')">
      <div class="wallet-balance-warp">
        <div class="wallet-balance-label">{{ i18n.balance }}</div>
        <div class="wallet-balance-value">
          {{ user && user.pcurrencyName }}{{ (user && user.pbalance) || 0 }}
        </div>
        <div class="wallet-balance-label">
          {{ i18n.deposit }}: {{ user.pyajin }}
        </div>
      </div>
      <div class="wallet-balance-more">
        <img src="../../assets/imgs/wallet_more.png" />
      </div>
    </div>
    <!-- <div class="wallet-btn">
      <div @click="goRouter('/wallet_in')">
        <img src="../../assets/imgs/wallet_pull.png">
        <span>{{i18n.wallet_in}}</span>
      </div>
      <div @click="goRouter('/wallet_out')">
        <img src="../../assets/imgs/wallet_push.png">
        <span>{{i18n.wallet_out}}</span>
      </div>
    </div> -->
  </page>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["user"]),
    i18n() {
      return this.$t("mine")
    },
    i18nMsg() {
      return this.$t("msg")
    }
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    }
  },
  created() {}
}
</script>

<style scoped lang="less">
.wallet-balance {
  margin: 40px;
  background: linear-gradient(90deg, #ebd460 0%, #e5be53 100%);
  border-radius: 20px;
  // border-top-right-radius: 20px;
  padding: 40px 60px 20px;
  color: #132e3d;
  display: flex;
  align-items: center;
  &-warp {
    flex: 1;
  }
  &-label {
    font-size: 32px;
  }
  &-value {
    font-size: 70px;
    font-weight: bold;
  }
  &-more {
    & img {
      width: 20px;
      height: 30px;
      display: block;
    }
  }
}
.wallet-btn {
  display: flex;
  padding: 20px 40px;
  & div {
    flex: 1;
    height: 72px;
    line-height: 72px;
    border-radius: 10px;
    text-align: center;
    border: 2px solid #333333;
    font-size: 28px;
    font-weight: 500;
    color: #132e3d;
    margin: 0 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & img {
    width: 40px;
    height: 40px;
    display: block;
  }
  & span {
    padding-left: 15px;
  }
}
</style>
