<template>
  <div id="app">
    <router-view />
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
import { PopupLoading } from "./components/popup"
import { mapState } from "vuex"
import { vConsoleWidget } from "@/utils/index"
export default {
  components: {
    PopupLoading
  },
  computed: {
    ...mapState(["loading"])
  },
  created() {
    const { vc } = this.$route.query
    if (vc === "true") vConsoleWidget()
  }
}
</script>

<style lang="less">
@import "./assets/css/public.less";
body {
  background: #f9f9f9;
  padding: 0;
  margin: 0;
  height: 100vh;
  color: #132e3d;
}
#app {
  font-size: 28px;
}
.btn {
  height: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  border-radius: 66px;
  border: none;
  padding: 0 40px;
  color: #fff;
  background: #93d500;
}
.downloadAppBox {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 10px #636363;
  z-index: 1;
  &-cancel {
    padding: 20px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      margin: 0 20px;
      border-radius: 10px;
    }
  }
  &-btn {
    padding: 0 20px;
    margin-right: 20px;
  }
}
</style>
