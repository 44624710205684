<template>
  <div class="saoma">
    <QrcodeStream
      @decode="onDecode"
      @init="onInit"
      style="height: 100vh; width: 100vw"
    >
      <div>
        <div class="qr-back">
          <img src="../../assets/imgs/back.png" alt="" />
        </div>
        <div class="qr-scanner">
          <div class="box">
            <div class="line"></div>
            <div class="angle"></div>
          </div>
        </div>
      </div>
    </QrcodeStream>
  </div>
</template>
<script>
import { QrcodeStream } from "vue-qrcode-reader"
export default {
  components: {
    QrcodeStream
  },
  computed: {
    i18nScanQr() {
      return this.$t("scanQr")
    }
  },
  data() {
    return {
      result: "", // 扫码结果信息
      error: "" // 错误信息
    }
  },
  methods: {
    onDecode(result) {
      if (result) {
        console.log("扫码结果", result)
        this.$emit("scanQr", result)
      }
      // this.$router.go(-1)
    },
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.$router.push({ path: "/" })
          window.alert(this.i18nScanQr.NotAllowedError)
        } else if (error.name === "NotFoundError") {
          this.$router.push({ path: "/" })
          window.alert(this.i18nScanQr.NotFoundError)
        } else if (error.name === "NotSupportedError") {
          this.$router.push({ path: "/" })
          window.alert(this.i18nScanQr.NotSupportedError)
        } else if (error.name === "NotReadableError") {
          this.$router.push({ path: "/" })
          window.alert(this.i18nScanQr.NotReadableError)
        } else if (error.name === "OverconstrainedError") {
          this.$router.push({ path: "/" })
          window.alert(this.i18nScanQr.OverconstrainedError)
        } else if (error.name === "StreamApiNotSupportedError") {
          this.$router.push({ path: "/" })
          window.alert(this.i18nScanQr.StreamApiNotSupportedError)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
@bgLineColor: rgba(137, 196, 230, 0.05);
@QrCodeLineColor: #93d500;
.saoma {
  width: 100vw;
  height: 100vh;
}
.qr-scanner {
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      @bgLineColor 25%,
      @bgLineColor 26%,
      transparent 27%,
      transparent 74%,
      @bgLineColor 75%,
      @bgLineColor 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      @bgLineColor 25%,
      @bgLineColor 26%,
      transparent 27%,
      transparent 74%,
      @bgLineColor 75%,
      @bgLineColor 76%,
      transparent 77%,
      transparent
    );
  background-size: 3rem 3rem;
  background-position: -1rem -1rem;
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  position: relative;
  background-color: #1110;
  /* background-color: #111; */
}
.qr-scanner .box {
  width: 282px;
  height: 282px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border: 0.1rem solid rgba(137, 196, 230, 0.2);
  /* background: url('http://resource.beige.world/imgs/gongconghao.png') no-repeat center center; */
}
.qr-scanner .line {
  height: calc(100% - 2px);
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(15, 126, 215, 0) 43%,
    @QrCodeLineColor 211%
  );
  border-bottom: 3px solid @QrCodeLineColor;
  transform: translateY(-100%);
  animation: radar-beam 2s infinite alternate;
  animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
  animation-delay: 1.4s;
}
.qr-scanner .box:after,
.qr-scanner .box:before,
.qr-scanner .angle:after,
.qr-scanner .angle:before {
  content: "";
  display: block;
  position: absolute;
  width: 3vw;
  height: 3vw;
  border: 0.2rem solid transparent;
}
.qr-scanner .box:after,
.qr-scanner .box:before {
  top: 0;
  border-top-color: @QrCodeLineColor;
}
.qr-scanner .angle:after,
.qr-scanner .angle:before {
  bottom: 0;
  border-bottom-color: @QrCodeLineColor;
}
.qr-scanner .box:before,
.qr-scanner .angle:before {
  left: 0;
  border-left-color: @QrCodeLineColor;
}
.qr-scanner .box:after,
.qr-scanner .angle:after {
  right: 0;
  border-right-color: @QrCodeLineColor;
}
.qr-back {
  position: absolute;
  top: 92px;
  left: 40px;
  img {
    width: 64px;
    height: 64px;
  }
}
@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
