<template>
  <page :title="i18n.title">
    <div class="wallet-in-warp">
      <div class="wallet-form">
        <div class="wallet-label">{{ i18n.price_label }}</div>
        <div class="wallet-input">
          <span class="wallet-input-tips">Ksh</span>
          <input
            type="number"
            v-model="price"
            :placeholder="i18n.price_placeholder"
            readonly="readonly"
          />
        </div>
      </div>
      <div class="wallet-balance" v-if="!wallet">
        {{ i18n.balance }}:<span>Ksh {{ pbalance }}</span>
      </div>
      <div class="wallet-balance" v-else>
        {{ i18n.balance }}:<span
          >{{ wallet && wallet.pcurrencySymbol }}
          {{ (wallet && wallet.pbalance) || 0 }}</span
        >
      </div>
      <div class="wallet-in-prices-warp">
        <div class="wallet-in-label">{{ i18n.price_auto }}</div>
        <div class="wallet-in-prices">
          <div
            class="wallet-in-price"
            v-for="(item, i) in prices"
            :key="i"
            @click="onItem(item)"
          >
            <div :class="{ active: `${item}` === `${price}` }">
              Ksh {{ item }}
            </div>
          </div>
        </div>
        <span class="tag">{{ i18n.price_tips }}</span>
      </div>
      <div class="wallet-btn">
        <button class="btn" @click="onSubmit">{{ i18n.submit }}</button>
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["user", "browser", "wallet"]),
    i18n() {
      return this.$t("wallet_in")
    },
    i18nMsg() {
      return this.$t("msg")
    }
  },
  data() {
    return {
      price: 10,
      prices: [10, 20, 50, 100, 200, 500],
      query: null,
      pbalance: 0
    }
  },
  methods: {
    onItem(price) {
      this.price = price
    },
    onSubmit(event) {
      this.$router.push(
        `/confirm?price=${this.price}&payType=CZ${
          this.query.price ? `&cabinetID=${this.query.cabinetID}` : ""
        }`
      )
    }
  },
  created() {
    this.pbalance = this.$route.query.pbalance
    this.query = this.$route.query
    if (this.query && this.query.price > 0) {
      this.price = this.query.price
    }
  },
  destroyed() {
    // this.$store.commit('cabinet', null)
  }
}
</script>

<style lang="less">
.wallet-form {
  background: #ffffff;
  padding: 40px 40px 0;
}
.wallet-label {
  font-size: 32px;
  font-weight: 400;
  color: #132e3d;
}
.wallet-input {
  padding: 20px 0;
  display: flex;
  align-items: center;
  font-size: 56px;
  font-weight: 500;
  color: #132e3d;
}
.wallet-input-tips {
  height: 78px;
  line-height: 78px;
}
.wallet-input input {
  width: 300px;
  flex: 1;
  height: 78px;
  line-height: 78px;
  padding: 0 20px;
  color: #132e3d;
  font-size: 64px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}
.wallet-balance {
  background: #ffffff;
  border-top: 1px solid rgba(217, 217, 217, 1);
  font-size: 32px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  padding: 26px 40px;
}
.wallet-balance span {
  font-size: 36px;
  font-weight: 500;
  color: rgba(241, 52, 52, 1);
}
.wallet-in-prices-warp {
  margin-top: 20px;
  background: #ffffff;
  padding: 26px 40px;
  font-size: 32px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.wallet-in-prices {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.wallet-in-price {
  padding: 20px 17px 0 0;
  & div.active {
    background: #e5be53;
    color: #ffffff;
  }
}
.wallet-in-price:nth-of-type(3n + 0) {
  padding-right: 0;
}
.wallet-in-price div {
  width: 208px;
  height: 80px;
  line-height: 80px;
  border-radius: 10px;
  border: 1px solid #e4e7ed;
  font-size: 28px;
  font-weight: 400;
  color: #132e3d;
  text-align: center;
}
.wallet-btn {
  padding: 55px;
  button {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }
}
</style>
