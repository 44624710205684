<template>
  <page :title="i18n.order_details">
    <div class="order-details">
      <div class="order-head">
        <div
          class="order-status"
          v-if="
            orderDetails.pZujie == 3 &&
            (orderDetails.pfukuan == 0 ||
              orderDetails.palipayjsSm == '代扣支付失败')
          "
        >
          <div class="order-unpay">
            <span class="order-unpay-des">{{ $t("msg.unpay") }}</span>
            <p class="order-unpay-payment btn" @click="orderPayment">
              {{ $t("confirm.paynow") }}
            </p>
          </div>
        </div>
        <div class="order-status" v-else>
          <span class="order-status-text" :class="orderStatusType">{{
            orderStatusText
          }}</span>
        </div>
      </div>
      <ul class="order-info">
        <li class="order-info-li">
          <div class="order-info-value order-info-orderid">
            {{ i18n.order_number + orderDetails.porderid }}
          </div>
        </li>
        <li class="order-info-line"></li>
        <li class="order-info-li">
          <div class="order-info-label">{{ i18n.order_zj_shop }}</div>
          <div class="order-info-value">
            {{ orderDetails.pshopName || "-" }}
          </div>
        </li>
        <li class="order-info-li">
          <div class="order-info-label">{{ i18n.order_zj_time }}</div>
          <div class="order-info-value">
            {{ orderDetails.pborrowtime | dateFmt }}
          </div>
        </li>
        <li class="order-info-line"></li>
        <li class="order-info-li">
          <div class="order-info-label">{{ i18n.order_gh_shop }}</div>
          <div class="order-info-value">
            {{ orderDetails.pghShopName || "-" }}
          </div>
        </li>
        <li class="order-info-li">
          <div class="order-info-label">{{ i18n.order_gh_time }}</div>
          <div class="order-info-value" v-if="orderDetails.pghtime">
            {{ orderDetails.pghtime | dateFmt }}
          </div>
        </li>
        <li class="order-info-line"></li>
        <li class="order-info-li">
          <div class="order-info-label">{{ i18n.order_use_time }}</div>
          <div class="order-info-value">{{ orderDuration() }}</div>
        </li>
        <li class="order-info-li">
          <div class="order-info-label">{{ i18n.order_amount }}</div>
          <!-- <div class="order-info-value">{{ orderAmount() }}</div> -->
          <div class="order-info-value">
            {{ orderDetails.pCurrencyName }}
            {{ (orderDetails.pJiesuan || 0).toFixed(2) }}
          </div>
        </li>
      </ul>
    </div>
  </page>
</template>

<script>
import { ORDER_DETAILS } from "../../apis/user"
export default {
  components: {},
  computed: {
    i18n() {
      return this.$t("order")
    },
    // 订单状态
    orderStatusText() {
      const statusList = [
        this.i18n.not_on_rent,
        this.i18n.renting,
        this.i18n.undone,
        this.i18n.returned,
        this.i18n.questions,
        this.i18n.timeout_to_return,
        this.i18n.suspended_for_billing
      ]
      return statusList[this.orderDetails.pZujie]
    },
    orderStatusType() {
      const state = this.orderDetails.pZujie
      const typeList = []
      if (state === 1) {
        typeList.push("order-status-lease")
      }
      return typeList.join(" ")
    }
  },
  data() {
    return {
      orderDetails: {
        pZujie: 0,
        pborrowtime: 0,
        porderid: "",
        pshopName: "-",
        pghShopName: "-"
      }
    }
  },
  methods: {
    // 订单支付
    orderPayment() {
      const order = this.orderDetails
      this.$router.push({
        path: "/confirm",
        query: {
          price: order.pJiesuan,
          payType: "FK",
          outTradeNo: order.porderid,
          cabinetID: order.pCabinetid
        }
      })
    },
    // 格式化时间
    orderDateFmt(seconds = 0) {
      let hours = Number.parseInt(seconds / 3600)
      let minutes = Number.parseInt((seconds - hours * 3600) / 60)
      let second = Number.parseInt(seconds - hours * 3600 - minutes * 60)
      second = second < 10 ? "0" + second : second
      hours = hours < 10 ? "0" + hours : hours
      minutes = minutes < 10 ? "0" + minutes : minutes
      return `${hours}:${minutes}:${second}`
    },
    // 订单时长
    orderDuration() {
      const order = this.orderDetails
      let seconds = order.puseMins * 60
      if (order.pZujie === 1) {
        const nT = new Date(this.$dateFmt()).getTime()
        const bT = new Date(order.pborrowtime).getTime()
        seconds = parseInt((nT - bT) / 1000)
      }
      return this.orderDateFmt(seconds || 0)
    },
    // 订单金额计算
    amountFmt(_order, seconds) {
      const hour = seconds / 3600
      const days = hour / 24
      let amount
      // 免费时间段
      if (seconds <= (_order.mian + 1) * 60) {
        amount = 0
      } else if (days >= _order.yajin / _order.pfengding) {
        amount = _order.yajin
      } else {
        const hours = (hour % 24) + 1
        let jifei = hours * _order.pjifei
        if (jifei > _order.pfengding) {
          jifei = _order.pfengding
        }
        amount = days * _order.pfengding + jifei
      }
      return amount
    },
    // 订单金额
    orderAmount() {
      const order = this.orderDetails
      let amount = order.pJiesuan || 0
      if (order.puseMins === 0 && order.pZujie === 1) {
        const nT = Date.now() - 8 * 3600 * 1000
        const bT = new Date(order.pborrowtime).getTime()
        amount = this.amountFmt(order, parseInt((nT - bT) / 1000))
      }
      return `${order.pcurrencyName || ""} ${amount.toFixed(2)}`
    },
    async getOrderDetails() {
      try {
        const { orderId } = this.$route.params
        this.$loading(true)
        const result = await this.$Request("get", ORDER_DETAILS, { orderId })
        if (result && result.code === 0) {
          this.orderDetails = result.data || {}
        }
        this.$loading(false)
      } catch (err) {
        this.$loading(false)
        this.$toast(err)
        console.warn("~ err:", err)
      }
    }
  },
  created() {},
  mounted() {
    this.getOrderDetails()
  }
}
</script>

<style lang="less" scoped>
.order-details {
  width: 100vw;
  height: calc(100vh - 88px);
  background-color: #fff;
}
.order-head {
  padding: 42px 0 56px;
  .order-status {
    display: flex;
    align-items: center;
    justify-content: center;
    &-text {
      padding: 36px 24px;
      border-bottom: 5px solid #c6c6c6;
      color: #c6c6c6;
      font-size: 52px;
    }
    &-lease {
      color: #398e3c;
      border-bottom-color: #41200f;
    }
  }
  .order-unpay {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    &-des {
      padding: 24px 24px 12px;
      font-size: 42px;
      color: #f13453;
      border-bottom: 4px solid #f13453;
    }
    &-payment {
      margin: 32px 0;
      padding: 0 32px;
    }
  }
}
.order-info {
  margin: 0 56px;
  &-li {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-line {
    margin: 12px 0;
    border-bottom: 1px solid rgb(213, 213, 213, 0.5);
  }
  &-label {
    color: #c6c6c6;
    font-size: 28px;
  }
  &-value {
    color: #333;
    font-size: 28px;
  }
  &-orderid {
    color: #41200f;
  }
}
</style>
