<template>
  <page :title="i18n.title">
    <div class="help-warp">
      <div class="help-body">
        <div class="help-items" v-if="items.length > 0">
          <div v-for="(item, i) in items" :key="i">
            <collapse-item
              v-if="item.pid"
              :item="item"
              :value="activeIndex === i"
              @input="onChange(i)"
            >
            </collapse-item>
            <div class="help-items-type" v-else>{{ item }}</div>
          </div>
        </div>
        <result-msg
          v-else
          :loading="loading"
          :text="error"
          @click="loadList"
        ></result-msg>
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
import { HELP_LIST } from "../../apis/user.js"
import CollapseItem from "../../components/help/collapse_item.vue"
import ResultMsg from "../../components/result/msg.vue"
export default {
  components: {
    CollapseItem,
    ResultMsg
  },
  computed: {
    ...mapState(["lang"]),
    i18n() {
      return this.$t("help")
    }
  },
  data() {
    return {
      activeIndex: 1,
      loading: false,
      error: "",
      items: []
    }
  },
  methods: {
    onChange(i) {
      this.activeIndex = i
    },
    loadList() {
      this.loading = true
      const faqTypeList = {
        0: this.i18n.rentals,
        5: this.i18n.venues,
        10: this.i18n.payments
      }
      const query = { classId: `help_${this.lang}` }
      this.$get(
        HELP_LIST,
        query,
        (resp) => {
          this.loading = false
          if (resp.list) {
            let items = resp.list || []
            items.forEach((item, i) => {
              if (Object.keys(faqTypeList).includes(`${i}`)) {
                items.splice(i, 0, faqTypeList[i])
              }
            })
            this.error = ""
            this.items = items
          } else {
            this.error = this.i18n.msg_fail_content
          }
        },
        (error) => {
          this.loading = false
          this.error = error
        }
      )
    }
  },
  created() {
    this.loadList()
  }
}
</script>

<style lang="less" scoped>
.help-warp {
  height: 100%;
  position: relative;
}
.help-head {
  position: fixed;
  width: 100%;
  z-index: 998;
  background: rgba(249, 249, 249, 1);
  /* #ifdef H5 */
  top: 88upx;
  /* #endif */
  /* #ifndef H5 */
  top: var(--window-top);
  /* #endif */
}
.help-body {
  position: absolute;
  /* top: 92upx; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
}
.help-items {
  padding: 20px 0;
  text {
    font-size: 28px;
    font-weight: 400;
  }
  &-type {
    margin: 12px 52px 24px;
    font-size: 52px;
    font-weight: 500;
    color: #333;
  }
}
</style>
