<template>
  <div class="menu-side">
    <div @click="onClick('hide_menu')">
      <div class="menu-side-title">
        <div class="user-info">
          <img src="../../assets/imgs/avatar.png" alt="" />
          <span v-if="user && user.pname">{{ user.pname | decode }}</span>
          <span v-else @click="goRouter('/login')">{{ i18n.login_tips }}</span>
          <!-- <span class="user-info-edit">{{ i18n.edit_profile }}</span> -->
        </div>
      </div>
      <!-- <div class="menu-side-row" @click="goRouter('/wallet')">
        <img src="../../assets/imgs/wallet.png" alt="">
        <span>{{i18n.wallet}}</span>
      </div> -->
      <div class="menu-side-row" @click="goRouter('/order')">
        <!-- <img src="../../assets/imgs/history.png" alt=""> -->
        <span>{{ i18n.rental_history }}</span>
      </div>
      <div class="menu-side-row" @click="goRouter('/payment_card')">
        <span>{{ i18n.payment_title }}</span>
      </div>
      <div class="menu-side-row" @click="goRouter('/download')">
        <span>{{ i18n.download_app }}</span>
      </div>
      <div class="menu-side-row" @click="goRouter('/setting')">
        <!-- <img src="../../assets/imgs/setting.png" alt=""> -->
        <span>{{ i18n.settings }}</span>
      </div>
      <div class="menu-side-row" @click="goRouter('/help')">
        <!-- <img src="../../assets/imgs/help.png" alt=""> -->
        <span>{{ i18n.help }}</span>
      </div>
      <div class="menu-side-line"></div>
      <div class="menu-side-row" @click="onLogout">
        <!-- <img src="../../assets/imgs/logout.png" alt=""> -->
        <span>{{ i18n.logout }}</span>
      </div>
    </div>
    <div class="menu-side-foot" @click="onClick('join_us')">
      <h3 class="menu-side-join">{{ i18n.join_us }}</h3>
      <p class="menu-side-join-text">{{ i18n.join_us_details }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["user"]),
    i18n() {
      return this.$t("mine")
    }
  },
  methods: {
    onClick(type) {
      if (type === "hide_menu") {
        this.$parent.$parent.isMenuView = false
      }
      if (type === "join_us") {
        let domain = process.env.VUE_APP_DOMAIN.replace("//m.", "//www.")
        window.open(domain)
      }
    },
    onLogout() {
      this.$store.commit("user", null)
      this.$store.commit("token", "")
      this.$router.push("/login")
    },
    goRouter(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less">
.menu-side {
  width: 100vw;
  height: 88vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;

  &-title {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 0 32px;
  }
  .user-info {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: #132e3d;
    font-size: 42px;
    img {
      width: 142px;
      height: 142px;
      border-radius: 50%;
      margin-bottom: 26px;
    }
    &-edit {
      font-size: 24px;
    }
  }

  &-row {
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;
    width: 60%;
    margin: 0 auto;
    color: #132e3d;

    img {
      width: 40px;
      height: 40px;
      margin: 0 24px 0 68px;
    }
  }

  &-foot {
    width: 100%;
    height: 200px;
    background: #93d500;
  }
  &-join {
    margin: 36px 0 8px;
    font-size: 48px;
    color: #fff;
    text-align: center;
    &-text {
      margin: 0;
      font-size: 28px;
      color: #fff;
      text-align: center;
    }
  }
  &-line {
    width: 60%;
    margin: 20px auto;
    border-bottom: 2px solid rgba(112, 112, 112, 0.16);
  }
}
</style>
